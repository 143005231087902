<template>
    <Section
        class="banner bg-cream"
        width="lg"
        :class="{ dropdowns: showDropDowns }"
    >
        <div class="container">
            <div>
                <h1 class="banner-inner__title">{{ $prismic.asText(data.primary.h1) }}</h1>

                <div
                    class="banner-inner__teaser list-icon list-checkmark"
                    v-html="$prismic.asHtml(data.primary.teaser)"
                />

                <div
                    v-if="showDropDowns"
                    class="banner-inner__dropdowns"
                >
                    <DropdownTopList
                        v-if="data.primary.dropdown_toplist_amount > 0"
                        :data="data"
                    />
                    <Dropdown
                        v-for="(dropdown, index) in filteredItems"
                        :key="index"
                        :header="dropdown.dropdown_header"
                        :data="$prismic.asHtml(dropdown.dropdown_content)"
                    />
                </div>
            </div>
        </div>

        <div class="container">
            <slot />
        </div>
    </Section>
</template>

<script>
import Dropdown from './Dropdown.vue';
import DropdownTopList from './DropdownTopList.vue';
export default {
    components: { Dropdown, DropdownTopList },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        const filteredItems = this.data.items.filter(this.$helpers.filterEmptyElements);
        return {
            showDropDowns: filteredItems.length > 0 ||
                this.data.primary.dropdown_toplist_amount > 0 &&
                this.data.primary.dropdown_toplist &&
                this.data.primary.dropdown_toplist.id,

            filteredItems: filteredItems
        };
    }
};
</script>

<style lang="scss" scoped>
.dropdowns::v-deep {
    .section-inner {
        @include device(pad) {
            align-items: flex-start!important;
        }
    }
}

.banner::v-deep {
    &:after {
        top: 25%;
        @include size(300px);

        @include device(pad) {
            left: -125px;
            top: calc( 100% - 225px );
            @include size(600px);
        }
    }

    .section-inner {
        @include grid(1, 40px);

        @include device(pad) {
            @include flex;
        }


        .container {
            @include device(pad) { flex: 1; }

            &:first-child {
                @include flex;

                .list-icon {
                    ul { @include grid(1, 20px); }
                }

                .banner-inner__dropdowns {
                    @include spacing(margin, 8, top);
                    width: 100%;
                    @include grid(1,10px);

                    .dropdown {
                        background: $white;
                        @include border-radius(5px);
                        box-shadow: $bxs;

                        &-header {
                            @include flex;
                            cursor: pointer;
                            @include spacing(padding,3);
                            @include font-size(16px);
                            font-family: $fontHeader;
                            color: $green;

                            &:after {
                                content: "\f0d7";
                                font: var(--fa-font-solid);
                                font-size: 22px;
                                color: $font;
                                transition: .3s ease-in-out;
                            }

                            span {
                                flex: 1;
                                position: relative;
                            }
                        }

                        &-body {
                            @include border(top);
                            @include spacing(padding,4);

                            p {
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    .dropdown.open {
                        // animation: left-slide .15s ease-in forwards;
                        .dropdown-header:after { transform: rotate(180deg); }
                    }
                }
            }

            &:last-child {
                @include grid(1, 30px);
                text-align: center;
                background: $white;
                box-shadow: $bxs;
                @include border-radius(5px);
                @include spacing(padding, 8);

                @include device(pad) {
                    padding-left: 60px;
                }

                img {
                    @include border-radius(5px);
                    width: auto;
                }

                span {
                    font-family: $fontHeader;
                    font-weight: bold;
                    @include font-size(24px);
                    display: block;
                }
            }
        }
    }
}

.hide-lender::v-deep .section-inner {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    @include device(desktop) {
        grid-template-columns: 1.5fr repeat(1, 1fr) !important;
    }
    .container:last-child {
        display: none !important;
    }
}

</style>
