<template>
    <li>
        <!-- TODO Maybe use a component somewhere -->
        <a 
            :href="$helpers.getRedirectUrl(lender, 'list-text-toplist', position)" 
            target="_blank" 
            rel="nofollow"
        >
            <span>{{ lender.data.title }}</span>: {{ text }}
        </a>
    </li>
</template>
<script>
export default {
    props: {
        lenderId: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: true
        }
    },
    computed: {
        lender() {
            return this.$store.state.lendersCorporate[this.lenderId];
        },
        model() {
            return new this.$models.CorporateLoan(this.lender);
        },
        // Todo: translation lurig
        text() {
            return this.$translate('random', 'Låna {amount} i {duration}')
                .replace('{amount}', this.model.amountString)
                .replace('{duration}', this.model.getDurationString());
        }
    }
};
</script>