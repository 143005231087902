<template>
    <Layout
        :lender-data="lender"
        :lender-model="model"
        :show-icons="true"
        :mortgage-layout="true"
    >
        <template #info>
            <div class="container">
                <PrismicImage
                    :img="lender.data.image"
                    w="100"
                    h="50"
                    :alt="lender.data.title"
                />
            </div>

            <div class="list-item-inner">
                <ul v-show="!showAverageInterest">
                    <li>
                        <span>{{ $translate('lm_3m_label', '3 mån (rörlig)') }}</span>
                        <span v-if="model.listInterest3m">{{ model.listInterestStrings['3m'] }}</span>
                        <span v-else><i class="fa-solid fa-question yellow" /></span>
                    </li>
                    <li>
                        <span>{{ $translate('lm_1y_label', '1 år') }}</span>
                        <span v-if="model.listInterest1y">{{ model.listInterestStrings['1y'] }}</span>
                        <span v-else><i class="fa-solid fa-question yellow" /></span>
                    </li>
                    <li>
                        <span>{{ $translate('lm_3y_label', '3 år') }}</span>
                        <span v-if="model.listInterest3y">{{ model.listInterestStrings['3y'] }}</span>
                        <span v-else><i class="fa-solid fa-question yellow" /></span>
                    </li>
                </ul>
                <ul v-show="showAverageInterest">
                    <li>
                        <span>{{ $translate('lm_3m_label', '3 mån (rörlig)') }}</span>
                        <span v-if="model.averageInterest3m">
                            {{ model.averageInterestStrings['3m'] }}
                        </span>
                        <span v-else><i class="fa-solid fa-question yellow" /></span>
                    </li>
                    <li>
                        <span>{{ $translate('lm_1y_label', '1 år') }}</span>
                        <span v-if="model.averageInterest1y">{{ model.averageInterestStrings['1y'] }}</span>
                        <span v-else><i class="fa-solid fa-question yellow" /></span>
                    </li>
                    <li>
                        <span>{{ $translate('lm_3y_label', '3 år') }}</span>
                        <span v-if="model.averageInterest3y">{{ model.averageInterestStrings['3y'] }}</span>
                        <span v-else><i class="fa-solid fa-question yellow" /></span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span>{{ $translate('calc_car_down_payment_label', 'Kontantinsats') }}</span>
                        <span>{{ model.downPaymentString }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('lf_setup_fee_label', 'Uppläggningsavgift') }}</span>
                        <span>{{ model.setupFeeString }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('lf_avi_fee_label', 'Aviavgift') }}</span>
                        <span>
                            {{ model.getAviFeeString(
                                $translate('lf_avi_fee_value', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')
                            ) }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>

        <template #apply>
            <ButtonTarget
                size="md"
                background="green"
                :text="$translate('lm_submit', 'Ansök om lånelöfte')"
                :link="lender"
                placement="list"
                :position="position"
            />
        </template>

        <template #expand>
            <ul>
                <li class="head">{{ $translate('lm_list_interest_header', 'Listränta') }}</li>
                <li v-if="model.listInterest3m">
                    <span>{{ $translate('lm_3m_label', '3 mån (rörlig)') }}:</span>
                    <span>{{ model.listInterestStrings['3m'] }}</span>
                </li>
                <li v-if="model.listInterest1y">
                    <span>{{ $translate('lm_1y_label', '1 år') }}:</span>
                    <span>{{ model.listInterestStrings['1y'] }}</span>
                </li>
                <li v-if="model.listInterest2y">
                    <span>{{ $translate('lm_2y_label', '2 år') }}:</span>
                    <span>{{ model.listInterestStrings['2y'] }}</span>
                </li>
                <li v-if="model.listInterest3y">
                    <span>{{ $translate('lm_3y_label', '3 år') }}:</span>
                    <span>{{ model.listInterestStrings['3y'] }}</span>
                </li>
                <li v-if="model.listInterest5y">
                    <span>{{ $translate('lm_5y_label', '5 år') }}:</span>
                    <span>{{ model.listInterestStrings['5y'] }}</span>
                </li>
                <li v-if="model.listInterest7y">
                    <span>{{ $translate('lm_7y_label', '7 år') }}:</span>
                    <span>{{ model.listInterestStrings['7y'] }}</span>
                </li>
                <li v-if="model.listInterest10y">
                    <span>{{ $translate('lm_10y_label', '10 år') }}:</span>
                    <span>{{ model.listInterestStrings['10y'] }}</span>
                </li>
            </ul>

            <ul>
                <li class="head">{{ $translate('lm_average_interest_header', 'Snittränta') }}</li>
                <li v-if="model.averageInterest3m">
                    <span>{{ $translate('lm_3m_label', '3 mån (rörlig)') }}:</span>
                    <span>{{ model.averageInterestStrings['3m'] }}</span>
                </li>
                <li v-if="model.averageInterest1y">
                    <span>{{ $translate('lm_1y_label', '1 år') }}:</span>
                    <span>{{ model.averageInterestStrings['1y'] }}</span>
                </li>
                <li v-if="model.averageInterest2y">
                    <span>{{ $translate('lm_2y_label', '2 år') }}:</span>
                    <span>{{ model.averageInterestStrings['2y'] }}</span>
                </li>
                <li v-if="model.averageInterest3y">
                    <span>{{ $translate('lm_3y_label', '3 år') }}:</span>
                    <span>{{ model.averageInterestStrings['3y'] }}</span>
                </li>
                <li v-if="model.averageInterest5y">
                    <span>{{ $translate('lm_5y_label', '5 år') }}:</span>
                    <span>{{ model.averageInterestStrings['5y'] }}</span>
                </li>
                <li v-if="model.averageInterest7y">
                    <span>{{ $translate('lm_7y_label', '7 år') }}:</span>
                    <span>{{ model.averageInterestStrings['7y'] }}</span>
                </li>
                <li v-if="model.averageInterest10y">
                    <span>{{ $translate('lm_10y_label', '10 år') }}:</span>
                    <span>{{ model.averageInterestStrings['10y'] }}</span>
                </li>
            </ul>

            <ul>
                <li class="head">{{ $translate('lm_info_header', 'Information') }}</li>
                <li v-if="showLenders">
                    <span>{{ $translate('lm_connected_banks_label', 'Anslutna långivare') }}:</span>
                    <span>{{ model.connectedBanks }}</span>
                </li>
                <li>
                    <span>{{ $translate('lm_loan_amount_label', 'Belåningsgrad') }}:</span>
                    <span>{{ model.maxLTVRatioString }}</span>
                </li>
                <li>
                    <span>{{ $translate('lm_min_cash_deposit_label', 'Minsta kontantinsats') }}:</span>
                    <span>{{ model.downPaymentString }}</span>
                </li>
                <li v-if="model.minAge">
                    <span>{{ $translate('ld_age_limit_label', 'Ålderskrav') }}</span>
                    <span>
                        {{ $translate('ld_age_limit_value', '{minAge} eller äldre')
                            .replace('{minAge}', model.minAge)
                        }}
                    </span>
                </li>
                <li>
                    <span>{{ $translate('ld_part_time_job_label', 'Accepterar deltidsjobb') }}</span>
                    <span>{{ $formatBoolean(model.acceptsPartTimeJob) }}</span>
                </li>
                <li>
                    <span>{{ $translate('ld_payment_remarks_label', 'Betalningsanmärkningar') }}</span>
                    <span>{{ model.acceptsPaymentRemarks ? $translate('accepted', 'Accepteras') : $translate('not_accepted', 'Accepteras inte') }}</span>
                </li>
                <li>
                    <span>{{ $translate('lf_setup_fee_label', 'Uppläggningsavgift') }}</span>
                    <span>{{ model.setupFeeString }}</span>
                </li>
                <li>
                    <span>{{ $translate('lf_avi_fee_label', 'Aviavgift') }}</span>
                    <span>{{ model.getAviFeeString($translate('lf_avi_fee_value', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')) }}</span>
                </li>
            </ul>
        </template>
    </Layout>
</template>

<script>
import Layout from '@/components/slices/lists/list-items/components/Template';
export default {
    components: { Layout },
    mixins: [Layout],
    props: {
        id: {
            type: String,
            required: true,
        },
        position: {
            type: Number,
            required: false,
            default: 0,
        },
        showAverageInterest: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        showLenders() {
            return this.model.isBroker === true;
        },
        lender() {
            return this.$store.state.lendersMortgage[this.id];
        },
        model() {
            return new this.$models.Mortgage(this.lender, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
.list-item::v-deep {
    .expand {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        ul {
            margin: 0 !important;
            li {
                font-size: 14px;
            }
            @media screen and (max-width: 900px) {
                li {
                    &.head {
                        text-align: left;
                    }
                    padding: 8px 16px;
                    span:first-child {
                        text-align: left;
                    }
                    span:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}
.list-item-inner {
    @include spacing(padding, 4);
    @include grid(1, 0);
    width: 100%;

    @include device(pad) {
        flex: 1;
        width: auto;
        @include grid(2, 30px);
    }

    ul {

        &:last-child {
            @include device(mobile) {
                @include border(top);
            }
        }

        li {
            @include flex;
            @include border(bottom);
            font-size: 14px;
            @include spacing(padding, 2, (bottom,top));

            &:last-child { border: 0; }

            span {
                text-align: left;
                flex: 1;

                @include device(pad) {
                    flex: none;
                    width: 100%;
                }

                @include device(desktop) {
                    width: auto;
                }

                &:last-child::v-deep {
                    text-align: right;
                    width: 100%;
                    font-weight: bold;

                    @include device(pad) {
                        text-align: left;
                        margin-top: 10px;
                    }

                    @include device(desktop) {
                        text-align: right;
                        flex: 1;
                        margin: 0;
                        margin-left: 10px;
                    }

                    i { margin-right: 10px; }
                }
            }
        }
    }
}
</style>
