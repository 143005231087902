<template>
    <Layout :data="data" :class="{'hide-lender': !showLender}">
        <template v-if="showLender">
            <nuxt-link 
                v-if="$prismic.linkResolver(lender)" 
                :to="$prismic.linkResolver(lender)" 
                :name="lender.data.title"
            >
                <PrismicImage
                    :lazy="false"
                    :img="lender.data.image"
                    w="200"
                    h="100"
                />
            </nuxt-link>
            <div v-else>
                <PrismicImage
                    :lazy="false"
                    :img="lender.data.image"
                    w="200"
                    h="100"
                />
            </div>
            <h2>{{ data.primary.lender_category }}</h2>

            <div v-html="$prismic.asHtml(data.primary.lender_category_short_text)" />

            <ButtonTarget
                :text="ctaText"
                background="green"
                :link="lender"
                size="md"
                placement="featured"
            />

            <!-- TODO: Norway too -->
            <div
                v-if="data.primary.lender.type === 'lender' && $isSweden()"
                class="container-inner"
            >
                <p>{{ $translate('cta_to_loan_guide_p', 'Vart får jag låna?') }}</p>

                <nuxt-link
                    class="green"
                    to="/laneguiden/"
                >
                    {{ $translate('cta_to_loan_guide_link', 'Starta låneguiden') }}
                </nuxt-link>
            </div>
        </template>
    </Layout>
</template>

<script>
import Layout from '@/components/slices/banners/components/Template.vue';
export default {
    components: { Layout },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        document: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    computed: {
        lender() {
            let type = this.lenderType(this.data.primary.lender.type);
            return this.$store.state[type][ this.data.primary.lender.id ];
        },
        showLender() {
            return this.lender.id && !this.lender.data.lists_disabled;
        },
        ctaText() {
            if (this.$validateText(this.data.primary.cta_text)) {
                return this.data.primary.cta_text;
            }
            return this.$translate('cta_to_application', 'Till ansökan');
        }
    },
    methods: {
        lenderType(type) {
            if (type.includes('_')) {
                let split = type.split('_');
                return split[0] + 's' + split[1].charAt(0).toUpperCase() + split[1].slice(1);
            }
            return type + 's';
        }
    }
};
</script>
<style lang="scss" scoped>
img {
    height: auto;
    width: 200px;
}
    .container:last-child {
        .container-inner {
            @include flex;

            a {
                text-decoration: none;
                margin-left: 10px;

                &:hover { text-decoration: underline; }
            }
        }
    }
h2 {
    @include font-size(24px);
}
</style>
