<template>
    <Layout
        :lender-data="lender"
        :lender-model="model"
    >
        <template #info>
            <nuxt-link
                :to="$prismic.linkResolver(lender)"
                class="container"
            >
                <PrismicImage
                    :img="lender.data.image"
                    w="100"
                    h="50"
                    :alt="lender.data.title"
                />
            </nuxt-link>

            <div class="container">
                <span>{{ $translate('max_amount', 'Maxbelopp') }}</span>
                <span>{{ model.maxAmountString }}</span>
            </div>

            <div class="container">
                <span>{{ $translate('duration', 'Löptid') }}</span>
                <span>{{ model.getDurationString($translate('month_short', 'mån'), $translate('year', 'år')) }}</span>
            </div>

            <div class="container">
                <span>{{ $translate('without_security_in_the_company', 'Utan säkerhet i företaget') }}:</span>
                <span v-html="$helpers.boolean(model.requiresCollateral)" />
            </div>
        </template>

        <template #apply>
            <ButtonTarget
                size="md"
                background="green"
                :text="$translate('cta_to_application', 'Till ansök')"
                :link="lender"
                placement="list"
                :position="position"
            />
        </template>

        <template #expand>
            <Stats :model="model" />

            <div class="expand-inner">
                <ButtonNuxt
                    :link="lender"
                    size="sm"
                    background="blue"
                    :text="$translate('cta_read_review', 'Läs recension')"
                />

                <!-- TODO: another placement or label or whatever? -->
                <ButtonTarget
                    :link="lender"
                    :text="$translate('cta_apply_direct', 'Ansök direkt')"
                    size="sm"
                    background="green"
                    placement="list"
                    :position="position"
                />
            </div>
        </template>
    </Layout>
</template>

<script>
import Layout from '@/components/slices/lists/list-items/components/Template';
import Stats from '@/components/pages/lender-corporate/LenderCorporateStats';
export default {
    components: { Stats, Layout },
    props: {
        id: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        lender() {
            return this.$store.state.lendersCorporate[this.id];
        },
        model() {
            return new this.$models.CorporateLoan(this.lender, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
    .expand-inner {
        width: 100%;
        @include flex;
        @include border(top);
        @include spacing(padding,6,(left, top, right));

        .btn { margin: 0 5px; }
    }
</style>
