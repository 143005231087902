<template>
    <ListTemplate
        :data="data"
        :list="originalList"
        :stats-text="statsText"
        :toplist-header="toplistHeader"
        :toplist-text="toplistText"
        :structured-data-enabled="Boolean(filteredList.length)"
    >
        <template #filter> 
            <filter-form 
                :filters="filters"
                :list-length="filteredList.length"
                :unfiltered-list-length="listToFilter.length"
                :active-filters-length="activeFilters.length"
                @updateCheckboxValue="updateCheckboxValue"
                @updateSliderValue="updateSliderValue"
            />
        </template>
        <template #list>
            <ul class="lender-list">
                <LenderCorporate
                    v-for="lender in filteredListCapped"
                    :id="lender.id"
                    :key="lender.id"
                />
            </ul>
            <button 
                v-if="!showAll && !useOriginalList && filteredList.length > 10" 
                class="btn btn-md btn-green show-more-button" 
                @click="showAll = true"
            >
                {{ $translate('filter_list_show_all', 'Visa alla') }}
            </button>
        </template>
        <template #stats>
            <tbody>
                <tr>
                    <td>{{ $translate('bl_stats_min_amount', 'Lägsta lånebelopp') }}</td>
                    <td>{{ $format.currency(stats.minAmount, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_max_amount', 'Högsta lånebelopp') }}</td>
                    <td>{{ $format.currency(stats.maxAmount, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_demands_personal_bail', 'Kräver personlig borgen') }}</td>
                    <td>
                        {{ 
                            $translate('bl_demands_personal_bail_calculation', '{amountOfLenders} långivare {procent} kräver personlig borgen')
                                .replace('{amountOfLenders}', stats.countRequiresPersonalBail)
                                .replace('{procent}', $format.percentage(100 * stats.requiresPersonalBail, 2)) 
                        }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_demands_security', 'Kräver säkerhet') }}</td>
                    <td>
                        {{ 
                            $translate('bl_demands_security_calculation', '{amountOfLenders} långivare ({procent}) kräver säkerhet')
                                .replace('{amountOfLenders}', stats.countRequiresCollateral)
                                .replace('{procent}', $format.percentage(100 * stats.requiresCollateral, 2))  
                        }} 
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_payment_remarks_company', 'Betalningsanmärkningar på företaget') }}</td>
                    <td>
                        {{ 
                            $translate('bl_payment_remarks_on_the_company_calculation', '{amountOfLenders} långivare ({procent}) godkänner betalningsanmärkningar på företaget')
                                .replace('{amountOfLenders}', stats.countAcceptsPaymentRemarksCompany)
                                .replace('{procent}', $format.percentage(100 * stats.acceptsPaymentRemarksCompany, 2))  
                        }} 
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_payment_remarks_on_the_company_signer', 'Betalningsanmärkningar på firmatecknaren') }}</td>
                    <td>
                        {{ 
                            $translate('bl_payment_remarks_on_the_company_signer_calculation', '{amountOfLenders} långivare ({procent}) godkänner betalningsanmärkningar på firmatecknaren')
                                .replace('{amountOfLenders}', stats.countAcceptsPaymentRemarksRepresentative)
                                .replace('{procent}', $format.percentage(100 * stats.acceptsPaymentRemarksRepresentative, 2))  
                        }} 
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_takes_uc_on_company', 'Tar uc på företaget') }}</td>
                    <td>
                        {{ 
                            $translate('bl_takes_uc_on_company_calculation', '{amountOfLenders} långivare ({procent}) gör en uc-kreditupplysning på företaget')
                                .replace('{amountOfLenders}', stats.countTakesUCCompany)
                                .replace('{procent}', $format.percentage(100 * stats.takesUCCompany, 2)) 
                        }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_takes_uc_on_company_signer', 'Tar uc på firmatecknaren') }}</td>
                    <td>
                        {{ 
                            $translate('bl_takes_uc_on_company_signer_calculation', '{amountOfLenders} långivare ({procent}) gör en uc-kreditupplysning på firmatecknaren')
                                .replace('{amountOfLenders}', stats.countTakesUCRepresentative)
                                .replace('{procent}', $format.percentage(100 * stats.takesUCRepresentative, 2)) 
                        }} 
                    </td>
                </tr>
            </tbody>
        </template>


        <template #toplist>
            <ol>
                <toplist-corporate-item 
                    v-for="(lender, index) in filteredListCapped.slice(0, 10)" 
                    :key="`toplist${lender.id}`" 
                    :lender-id="lender.id"
                    :position="index + 1" 
                />
            </ol>
        </template>
    </ListTemplate>
</template>

<script>
import ListTemplate from './ListTemplate.vue';
import map from 'lodash/map';
import LenderCorporate from '@/components/slices/lists/list-items/ListItemCorporate.vue';
import ToplistCorporateItem from '@/components/slices/lists/ToplistCorporateItem.vue';
import FilterForm from '@/components/slices/lists/filter/index.vue';

import FilterLogic from '@/components/slices/lists/filter/mixins.js';
export default {
    components: { 
        ListTemplate,
        LenderCorporate,
        ToplistCorporateItem,
        FilterForm
    },
    mixins: [FilterLogic],
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        statsText() {
            if (this.data.primary.stats_text.length > 0) {
                return this.$prismic.asHtml(this.data.primary.stats_text);
            }
            return '<p>' + this.$translate('bl_stats_description', 'För att hjälpa dig att fatta rätt beslut finns här en sammaställning av långivarnas egenskaper.' + '</p>');
        },

        toplistHeader() {
            const header = this.$prismic.asText(this.data.primary.toplist_h2);
            if (header) {
                return header;
            }
            return this.$translate('bl_toplist_h2', 'Topplista');
        },

        toplistText() {
            if (this.data.primary.toplist_text.length > 0) {
                return this.$prismic.asHtml(this.data.primary.toplist_text);
            }
            return '<p>' + this.$translate('bl_toplist_summary_description', 'Sammanfattning över vår topplista.' + '</p>');
        },
        list() {
            if (this.data.primary.predefined_list.id) {
                let list = this.$store.state.lists[this.data.primary.predefined_list.id];
                return map(list.data.lenders, 'lender');
            }
            return map(this.data.items, 'lender');
        },
        stats() {
            let count = 0; 

            let minAmount = 999999999999999;
            let maxAmount = 0;

            let countAcceptsPaymentRemarksCompany = 0;
            let countAcceptsPaymentRemarksRepresentative = 0;

            let countRequiresPersonalBail = 0;
            let countRequiresCollateral = 0;

            let countTakesUCCompany = 0;
            let countTakesUCRepresentative = 0;

            Object.values(this.filteredListCapped).forEach(lender => {

                if (lender.type !== 'lender_corporate') {
                    // should not happen but just for safety
                    return;
                }

                count++;

                const document = this.$store.state.lendersCorporate[lender.id]; 
                const model = new this.$models.CorporateLoan(document);

                if (model.minAmount < minAmount) {
                    minAmount = model.minAmount;
                }

                if (model.maxAmount > maxAmount) {
                    maxAmount = model.maxAmount;
                }

                if (model.acceptsPaymentRemarks && model.acceptsPaymentRemarks.company) {
                    countAcceptsPaymentRemarksCompany++;
                }

                if (model.acceptsPaymentRemarks && model.acceptsPaymentRemarks.repersentative) {
                    countAcceptsPaymentRemarksRepresentative++;
                }

                if (model.requiresCollateral) {
                    countRequiresCollateral++;
                }

                if (model.requiresPersonalBail) {
                    countRequiresPersonalBail++;
                }

                if (model.takesUC && model.takesUC.company) {
                    countTakesUCCompany++;
                }

                if (model.takesUC && model.takesUC.repersentative) {
                    countTakesUCRepresentative++;
                }
            });

            return {
                minAmount: minAmount,
                maxAmount: maxAmount,

                acceptsPaymentRemarksCompany: countAcceptsPaymentRemarksCompany / count,
                countAcceptsPaymentRemarksCompany: countAcceptsPaymentRemarksCompany,

                acceptsPaymentRemarksRepresentative: countAcceptsPaymentRemarksRepresentative / count,
                countAcceptsPaymentRemarksRepresentative: countAcceptsPaymentRemarksRepresentative,

                requiresCollateral: countRequiresCollateral / count,
                countRequiresCollateral: countRequiresCollateral,

                requiresPersonalBail: countRequiresPersonalBail / count,
                countRequiresPersonalBail: countRequiresPersonalBail,

                takesUCCompany: countTakesUCCompany / count,
                countTakesUCCompany: countTakesUCCompany,

                takesUCRepresentative: countTakesUCRepresentative / count,
                countTakesUCRepresentative: countTakesUCRepresentative
            };
        }
    }
};
</script>
