import { pick } from 'lodash';
import sortLenders from '@/plugins/sort-lenders.js';

export default {
    data() {
        const lenderTypeKeys = {
            storeKey: '',
            modelKey: '',
        };
        const filters = {
            checkboxes: {
                useOriginalList: {
                    title: this.getCheckboxUseOriginalListText(),
                    active: true,
                },
            },
            slider: {
                loanAmount: {
                    label: this.$translate('loan_amount', 'Lånebelopp'),
                    startValue: 0,
                    increment: '1000',
                    suffix: 'kr',
                    minValue: 0,
                    maxValue: 600000,
                    value: 0,
                    filterFunction: (model, loanAmount) => model.maxAmount >= loanAmount && model.minAmount <= loanAmount
                }
            }
        };
        this.setLenderTypeKeys(lenderTypeKeys);
        this.setFiltersFromLocale(filters, lenderTypeKeys);

        return {
            filters: filters,
            lenderTypeKeys: lenderTypeKeys,
            showAll: false,
        };
    },
    computed: {
        originalList() {
            return this.list
                .map(lender => this.$store.state[this.lenderTypeKeys.storeKey][lender?.id])
                .filter(lender => lender?.id && !lender.data.lists_disabled);
        },
        allLendersSorted() {
            
            // only use the lenders with active redirect
            const filtered = Object.values(this.$store.state[this.lenderTypeKeys.storeKey])
                .filter(lender => lender.data.redirect_enabled && !lender.data.lists_disabled);
            
            return sortLenders(
                filtered, 
                this.$models[this.lenderTypeKeys.modelKey]
            );
        },
        useOriginalList() {
            return this.filters.checkboxes.useOriginalList.active;
        },
        activeFilters() {
            const activeCheckboxes = Object.values(this.filters.checkboxes)
                .filter(category => category.active);
            
            if (this.filters.slider.loanAmount.value > 0) {
                return [...activeCheckboxes, this.filters.slider.loanAmount]; 
            }
            return activeCheckboxes;
        },
        activeFilterFunctions() {
            return this.activeFilters
                .filter(category => category.filterFunction !== undefined)
                .map(category => category.filterFunction);
        },
        listToFilter() {
            if (this.useOriginalList) {
                return this.originalList;
            }
            return this.allLendersSorted;
        },
        filteredList() {
            if (! this.data.primary.show_filter) {
                return this.originalList;
            }

            return this.listToFilter.filter(lender => {
                const model = new this.$models[this.lenderTypeKeys.modelKey](lender);
        
                return ! this.activeFilterFunctions.some(filterFunction => {
                    return ! filterFunction(model, this.filters.slider.loanAmount.value);
                });
            });
        },
        filteredListCapped() {
            if (!this.showAll && !this.useOriginalList) {
                return this.filteredList.slice(0, 10);
            }
            return this.filteredList;
        },
    },
    methods: {
        setFiltersFromLocale(filters, lenderTypeKeys) {
            if (this.$isSweden()) {
                if (lenderTypeKeys.modelKey === 'CorporateLoan') {
                    return this.swedenCorporateLoanFilters(filters);
                }
                return this.swedenLoanFilters(filters);
            }
            if (this.$isNorway()) {
                return this.norwayLoanFilters(filters);
            }
        },
        swedenLoanFilters(filters) {
            const keys = [
                'withoutUc',
                'creditSafe',
                'bisnode',
                'withoutUC',
                'acceptsPaymentRemarks',
                'hasDirectPayment',
                'withoutInterest',
            ];
            this.allFilters(filters, keys);
        },
        swedenCorporateLoanFilters(filters) {
            const keys = [
                'requiresCollateral',
                'isBroker',
            ];
            filters.slider.loanAmount.increment = '10000';
            filters.slider.loanAmount.maxValue = 10000000;
            this.allFilters(filters, keys);
        },
        norwayLoanFilters(filters) {
            const keys = [
                'acceptsPaymentRemarks',
                'minAgeUnder21'
            ];
            this.allFilters(filters, keys);
        },
        allFilters(filters, keys) {
            const checkboxes = {
                withoutUc: {
                    title: this.$translate('without_uc', 'Utan UC'),
                    active: false,
                    filterFunction: (model) => !model.takesUC
                },
                creditSafe: {
                    title: this.$translate('filter_creditsafe', 'Creditsafe'),
                    active: false,
                    filterFunction: (model) => model.creditCheck === 'creditsafe'
                },
                bisnode: {
                    title: this.$translate('filter_bisnode', 'Bisnode'),
                    active: false,
                    filterFunction: (model) => model.creditCheck === 'bisnode'
                },
                acceptsPaymentRemarks: {
                    title: this.$translate('filter_accepts_payment_remarks', 'Accepterar betalningsanmärkningar'),
                    active: false,
                    filterFunction: (model) => model.acceptsPaymentRemarks
                },
                hasDirectPayment: {
                    title: this.$translate('direct_payout', 'Direktutbetalning'),
                    active: false,
                    filterFunction: (model) => model.hasDirectPayments
                },
                withoutInterest: {
                    title: this.$translate('filter_without_interest', 'Räntefritt'),
                    active: false,
                    filterFunction: (model) => model.hasFirstTimeOffer
                },
                requiresCollateral: {
                    title: this.$translate('bl_demands_security', 'Kräver säkerhet'),
                    active: false,
                    filterFunction: (model) => model.requiresCollateral
                },
                isBroker: {
                    title: this.$translate('filter_is_broker', 'Är låneförmedlare'),
                    active: false,
                    filterFunction: (model) => model.isBroker
                },
                minAgeUnder21: {
                    title: this.$translate('filter_min_age_under_21', 'Åldersgräns under 21'),
                    active: false,
                    filterFunction: (model) => model.minAge < 21
                }
            };
            filters.checkboxes = {...filters.checkboxes, ...pick(checkboxes, keys)};

        },
        setLenderTypeKeys(lenderTypeKeys) {
            if (this.data.slice_type === 'list__selected_lenders') {
                lenderTypeKeys.storeKey = 'lenders';
                lenderTypeKeys.modelKey = 'Loan';
            }
            else if (this.data.slice_type === 'list__selected_corporate_lenders') {
                lenderTypeKeys.storeKey = 'lendersCorporate';
                lenderTypeKeys.modelKey = 'CorporateLoan';
            }
        },
        getCheckboxUseOriginalListText() {
            if (this.$validateText(this.data.primary.filter_use_original_list_text)) {
                return this.data.primary.filter_use_original_list_text;
            }
            return this.$translate('filter_use_original_list', 'Rekommenderade');
        },
        updateCheckboxValue(key) {
            this.filters.checkboxes[key].active = !this.filters.checkboxes[key].active;
        },
        updateSliderValue(data) {
            this.filters.slider[data.key].value = data.value;
        }
    }
};